import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import axiosBaseURL from '../baseUrl'; // Assuming baseURL is correctly set up for axios
import { baseUrl } from '../baseUrl'; // Ensure this is the correct baseUrl

const Team = () => {
  const [teams, setTeams] = useState([]); // State to store team data
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [error, setError] = useState(null); // State to handle any error

  useEffect(() => {
    // Fetch team data from the /api/teams endpoint
    const fetchTeams = async () => {
      try {
        const res = await axiosBaseURL.get('/api/teams');
        if (res.data?.status === 'success') {
          setTeams(res.data.teams); // Extract the teams array from the response
        } else {
          setError(res.data?.message || 'Failed to fetch team data'); // Handle API failure response
        }
        setLoading(false); // Set loading to false when response is handled
      } catch (err) {
        setError('Error fetching team data'); // Set error message
        setLoading(false); // Set loading to false if there's an error
        console.error(err); // Log any errors
      }
    };

    fetchTeams(); // Call the async function to fetch teams
  }, []); // Empty dependency array ensures this runs once on component mount

  // Render a loading state while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render an error message if there's an error fetching the data
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <section className="our_teams">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-header mt-5">
              <h4>
                Our <span>Team</span>
              </h4>
              <div className="after_line_teams"></div>
              <p className="team_subtitle">
                Discover the passionate individuals behind our success.
              </p>
            </div>
          </div>

          {/* Map through the teams data and display each team member */}
          {teams.length > 0 ? (
            teams.map((team) => (
              <div className="col-md-3 mb-4" key={team.id}> {/* Added mb-4 for spacing */}
                <div className="our_team_card d-flex flex-column h-100"> {/* Flexbox for equal height */}
                  <img
                    src={`${baseUrl}/images/team/${team.image}`}
                    alt={team.title || 'Team member'}
                    className="team_image"
                  />
                  <div className="team_info mt-auto text-center"> {/* Push content to bottom */}
                    <h5>{team.title}</h5>
                    <p>{team.role}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (

            <div className="col-md-12">
              <p>No team members found.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Team;
