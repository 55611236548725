import React from 'react'
import "../styles/footer.css"
import {Link} from 'react-router-dom'
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillTwitterSquare,
    AiOutlineCopyright,
    AiFillLinkedin
} from "react-icons/ai"
import tiktok from "../images/tiktok.png";

const Footer = ({footerData}) => {
    const footer = footerData ?. setting;
    const studyAbroad = footerData ?. abroads;
    const preparation = footerData ?. preparation;
    return (
        <div>
          <Link to={"https://gorkhatravel.com/"}  target={"_blank"}> 
            <div className="flight text-center">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M381 114.9L186.1 41.8c-16.7-6.2-35.2-5.3-51.1 2.7L89.1 67.4C78 73 77.2 88.5 87.6 95.2l146.9 94.5L136 240 77.8 214.1c-8.7-3.9-18.8-3.7-27.3 .6L18.3 230.8c-9.3 4.7-11.8 16.8-5 24.7l73.1 85.3c6.1 7.1 15 11.2 24.3 11.2H248.4c5 0 9.9-1.2 14.3-3.4L535.6 212.2c46.5-23.3 82.5-63.3 100.8-112C645.9 75 627.2 48 600.2 48H542.8c-20.2 0-40.2 4.8-58.2 14L381 114.9zM0 480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/></svg>
                <p>
                    <strong>Fly with Us
                    </strong>&nbsp;

                </p>
               
            </div>
            </Link>
            <div className="footer_main">
                <div className="container footer_container">
                    <div className="row">
                        <div className="col-md-3 quick_links">
                            <h5>Quick Links</h5>
                            <Link to={'/aboutus'}>
                                About Us</Link>
                            <Link to={'/gallery'}>
                                Gallery</Link>
                            <Link to={'/information'}>
                                Information</Link>
                            <Link to={'/messagefromceo'}>
                                Message From CEO</Link>
                            <Link to={'/contactus'}>
                                Contact Us</Link>
                        </div>
                        <div className="col-md-3 footer_services">
                            <h5>Services</h5>
                            {
                            studyAbroad && studyAbroad.map((item, index) => (
                                <Link to={
                                        `/abroad-study/${
                                            item ?. id
                                        }`
                                    }
                                    key={index}>
                                    {
                                    item.title
                                }</Link>
                            ))
                        }
                            {
                            preparation && preparation.map((item, index) => (
                                <Link to={
                                        `/preparation/${
                                            item ?. slug
                                        }`
                                    }
                                    key={index}>
                                    {
                                    item ?. title
                                }</Link>
                            ))
                        }
                            <Link to={'/courses'}>
                                Courses</Link>
                        </div>
                        <div className="col-md-3 other_links">
                            <h5>Other Links</h5>
                            <Link to={'/'}>
                                Privacy Policy</Link>
                            <Link to={'/'}>
                                Terms and Conditions</Link>
                            <Link to={'/bookappointment'}>
                                Book Appointment</Link>
                            <Link to={'/applynow'}>
                                Apply Now</Link>
                        </div>
                        <div className="col-md-3 map_section">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14129.27358663339!2d85.317794!3d27.707454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18e34b2fb8b9%3A0x5d15f2ed30d392bb!2sPrecious%20Voyage%20Educational%20Centre%20(P)%20Ltd!5e0!3m2!1sen!2snp!4v1679289085192!5m2!1sen!2snp" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='footer'></iframe>
                            <div className="social_media_links">
                                {
                                footer && footer.map((item, index) => (
                                    <div className="social_links"
                                        key={index}>
                                        <Link to={
                                                item ?. facebook
                                            }
                                            target="_blank"><AiFillFacebook size={40}
                                                style={
                                                    {color: "#fff"}
                                                }
                                                onMouseOver={
                                                    ({target}) => target.style.color = "#C75934"
                                                }
                                                onMouseOut={
                                                    ({target}) => target.style.color = "#fff"
                                                }/></Link>
                                        <Link to={
                                                item ?. instagram
                                            }
                                            target="_blank"><AiFillInstagram size={40}
                                                style={
                                                    {color: "#fff"}
                                                }
                                                onMouseOver={
                                                    ({target}) => target.style.color = "#C75934"
                                                }
                                                onMouseOut={
                                                    ({target}) => target.style.color = "#fff"
                                                }/></Link>
                                        <Link to={
                                                item ?. twitter
                                            }
                                            target="_blank"><AiFillTwitterSquare size={40}
                                                style={
                                                    {color: "#fff"}
                                                }
                                                onMouseOver={
                                                    ({target}) => target.style.color = "#C75934"
                                                }
                                                onMouseOut={
                                                    ({target}) => target.style.color = "#fff"
                                                }/></Link>
                                        <Link to={
                                                item ?. linkedin
                                            }
                                            target="_blank"><AiFillLinkedin size={40}
                                                style={
                                                    {color: "#fff"}
                                                }
                                                onMouseOver={
                                                    ({target}) => target.style.color = "#C75934"
                                                }
                                                onMouseOut={
                                                    ({target}) => target.style.color = "#fff"
                                                }/></Link>
                                        <Link to={
                                                item ?. tiktok
                                            }
                                            target="_blank"><img src={tiktok}
                                                alt="tiktok"
                                                className='tiktok'
                                                size={40}
                                                style={
                                                    {
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        width: "32px",
                                                        height: "32px",
                                                        borderRadius: "3px",
                                                        marginLeft: "4px",
                                                        padding: "6px"
                                                    }
                                                }
                                                onMouseOver={
                                                    ({target}) => target.style.backgroundColor = "#C75934"
                                                }
                                                onMouseOut={
                                                    ({target}) => target.style.backgroundColor = "#fff"
                                                }/></Link>
                                    </div>
                                ))
                            } </div>
                        </div>
                        <hr className='my_hr'></hr>
                        <div className="col-md-6 copy_right">
                            <p><AiOutlineCopyright size={18}/>&nbsp;&nbsp;Copyright 2023, PREVEC All Rights Reserved</p>
                        </div>
                        <div className="col-md-6 onviro">
                            <p>Developed And Designed By
                                <Link to={'https://onvirotech.com/'}
                                    target="_blank">OnviroTech Pvt. Ltd</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
